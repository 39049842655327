/* eslint-disable */
import {lazy} from "react";
import Cookies from "universal-cookie";
const appRoot = "";
const cookies = new Cookies(null, {path: "/"});
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
const dashboards = {
  default: lazy(() => import("views/dashboards/DashboardsDefault")),
  player: lazy(() => import("views/dashboards/DashboardsVisual")),
  cassino: lazy(() => import("views/dashboards/DashboardsCassino")),
  sportsbook: lazy(() => import("views/dashboards/DashboardsSportsbook")),
  persona: lazy(() => import("views/dashboards/DashboardsPersona")),
  financeiro: lazy(() => import("views/dashboards/DashboardsFinanceiro")),
  configuracoes: lazy(() => import("views/dashboards/DashboardsConfiguracoes")),
  blacklist: lazy(() => import("views/dashboards/DashboardsBlackList")),
  listPlayers: lazy(() => import("views/dashboards/DashboardsPlayers")),
  logs: lazy(() => import("views/dashboards/DashboardsLogs")),
  time: lazy(() => import("views/dashboards/DashboardsTime")),
  monitoring: lazy(() => import("views/dashboards/DashboardsMonitoring")),
  faq: lazy(() => import("views/dashboards/DashboardsFaq")),
  faqCreate: lazy(() => import("views/dashboards/DashboardsFaqCreate")),
  faqView: lazy(() => import("views/dashboards/DashboardsFaqView")),
  playerFinanceiro: lazy(() => import("views/dashboards/DashboardsPlyerFinanceiro")),
  message: lazy(() => import("views/dashboards/DashboardsMessage")),
  ranking: lazy(() => import("views/dashboards/DashboardsTopPlayers")),
  logAction: lazy(() => import("views/dashboards/DashboardsLogAction")),
  download: lazy(() => import("views/dashboards/DashboardLogDownload")),
  map: lazy(() => import("views/dashboards/DashboardMapRanking")),
  perms: lazy(() => import("views/dashboards/DashboardPerms")),
};
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
const financeiroList = {
  path: `${appRoot}/dashboards`,
  component: dashboards.default,
  label: "Dashboards",
  subs: [
    {path: `/geral`, label: "Geral", component: dashboards.default},
    {path: `/cassino`, label: "Cassino", component: dashboards.cassino},
    {path: `/sportsbook`, label: "Sportsbook", component: dashboards.sportsbook},
    {path: `/taxas`, label: "Taxas", component: dashboards.financeiro},
  ],
};

const playerList = {
  path: `${appRoot}/player`,
  label: "Jogadores",
  component: dashboards.listPlayers,
  subs: [
    {path: `/list`, label: "Lista de Players", component: dashboards.listPlayers},
    {path: `/top`, label: "Análise", component: dashboards.player},
    {path: `/financeiro`, label: "Financeiro", component: dashboards.playerFinanceiro},
    {path: `/persona`, label: "Persona", component: dashboards.persona},
    {path: `/blacklist`, label: "Lista Negra", component: dashboards.blacklist},
    {path: `/ranking`, label: "Ranking de Depósito", component: dashboards.ranking},
    {path: `/map`, label: "Mapa de Jogadores", component: dashboards.map},
  ],
};

const SupportList = {
  path: `${appRoot}/faq`,
  label: "Faq",
  component: dashboards.faq,
  subs: [
    {path: `/list`, label: "Listagem", component: dashboards.faq},
    {path: `/create`, label: "Criar Pergunta", component: dashboards.faqCreate},
    {path: `/view`, label: "Historico de Busca", component: dashboards.faqView},
    {path: `/edit/:id`, label: "Editar Pergunta", component: dashboards.faqCreate, hideInMenu: true},
  ],
};

const auditoriaList = {
  path: `${appRoot}/monitoring`,
  label: "Auditoria",
  component: dashboards.logs,
  subs: [
    {path: `/monitoring`, label: "Monitoramento", component: dashboards.monitoring},
    {path: `/message`, label: "Messages", component: dashboards.message},
    {path: `/execution`, label: "Tempo de Execução", component: dashboards.time},
    {path: `/logs`, label: "Logs", component: dashboards.logs},
  ],
};

const devList = {
  path: `${appRoot}/users`,
  label: "Usúarios",
  component: dashboards.logs,
  subs: [
    {path: `/perms`, label: "Permissões", component: dashboards.perms},
    {path: `/action`, label: "Ações de Usúario", component: dashboards.logAction},
    {path: `/download`, label: "Ações de Download", component: dashboards.download},
  ],
};
///////////////////////////////////////////////////////////////
///////////MONTAGEM DAS ROTAS POR NIVEL DE ACESSO//////////////
///////////////////////////////////////////////////////////////

const routesDev = {
  mainMenuItems: [
    {
      path: "/",
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboards`,
    },
    financeiroList,
    playerList,
    SupportList,
    auditoriaList,
    devList,
  ],
  sidebarItems: [],
};

export default routesDev;
