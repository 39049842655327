import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  isLogin: IS_DEMO,
  currentUser: IS_DEMO ? DEFAULT_USER : {},
  levelPermission: null, // Adiciona o levelPermission ao estado inicial
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload.user; // Assumindo que a ação recebe um objeto com user
      state.levelPermission = action.payload.levelPermission; // Atualiza o levelPermission
      state.isLogin = true;
    },
    logoutUser(state) {
      state.currentUser = {};
      state.levelPermission = null; // Reseta o levelPermission no logout
      state.isLogin = false;
    },
    // Se necessário, você pode adicionar uma ação específica para atualizar apenas o levelPermission
    setLevelPermission(state, action) {
      state.levelPermission = action.payload;
    },
  },
});

export const { setCurrentUser, logoutUser, setLevelPermission } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
